const en = {
    user: {
        "language": "language"
    },
    "store_name": "Store",
    "type_id_2": "Size",
    "type_id_3": "person",
    "type_id_4": "Pet",
    "type_id_5": "props",
    "total": "total",
    "total_price": "total price",
    "submit_tip": "Click to log in to submit and wait for the queue",
    "mobile_form_tip": "or fill in the mobile phone name registration",
    "title": "Line up for taking photos abroad",
    "err_tip_page": "The link parameter is wrong, the store information cannot be obtained",
    "pl_chose_goods": "Please choose Size",
    "pl_chose_attr": "Please choose the Posture",
    "success_queue": "Successful queuing",
    "fail_queue": "queuing failed",
    "email": "Email",
    "mobile": "Mobile phone number",
    "InternationalTelephoneAreaCode": "International telephone area code",
    "name": "Name",
    "submit": "Submit",
    "mobile_empty": "Mobile phone number cannot be empty",
    "name_empty": "Name cannot be empty",
    "email_empty": "Email cannot be empty",
    "tip": "The above is the reference price for a single photo. Please consult with the shop assistant for details.",
    "user_service_agreement": "user service agreement",
    "user_privacy_agreement": "privacy policy",
    "suwa_child_privacy_policy": "children privacy policy",
    "agree_and_continue": "agree and continue",
    "agree_text": "I agree",
    "privacy_hint_one": "Regardless of where your data is processed and stored, Swava will always collect and process your data in accordance with our privacy policy and user agreement.",
    "privacy_hint_two": "In compliance with the requirements of privacy protection laws in the mainland of China, you are required to sign this informed consent form.",
    "agreement_and_policy": "User Agreement and Privacy Policy",
    "agree_tip": "Please agree to the user service agreement and privacy policy",
    "queue_success": "Successful queuing",
    "your_queue_number_is": "Your queue number is",
    "Please_wait_for_the_number_to_be_called": "Please wait for the number to be called",
    "Number": "Number",
    "go_back": "Go back",
    "user_phone_is_empty": "User phone number is empty",
    "store_is_empty": "Store is empty",
    "calculate_total_amount_error": "Calculate total amount error",
    "create_user_error": "Create user error",
    "You_have_registered_please_wait": "You have registered, please wait",
    "server_error": "The server is having a small break, please try again later",
    "name_required": "Name is required",
    "mobile_required": "Mobile is required",
    "站姿": "Standing",
    "宠物+站姿": "Pet+Standing",
    "弯腰/半蹲/坐椅": "Bending/Squatting/Sit on a chair",
    "全蹲/坐地": "Full squat/Sitting",
    "宠物": "Pet",
    "道具": "Props",
    "suwa_user_service_agreement": "Suwa User Service Agreement",
    "suwa_privacy_policy": "Suwa Privacy Policy",
    "queue_buy_type": "Buy type",
    'loading': 'Loading',
    '加载中...': 'Loading...',
    "agree_tip_prefix": "I have read and agreed",
    "queue_price_tip": "The above is the reference price for a single photo. Please consult with the shop assistant for details.",
    "birthday": "birthday",
    "手办": "Collectible Figure",
    "3D相册": "3D Album",
    "体验3D相册": "Experience 3D Album",
    "元": "CNY",
    "合计：": "Total:",
    "您前面还有": "There are",
    "人请等待叫号": "people ahead of you, please wait for your turn.",
    "返回": "Go back",
    "排队成功": "Queue successful",
    "3D摄影": "3D Photography",
    "排队": "Queue",
    "登录": "Login",
    "客户手机号和客户编号不能同时为空": "The customer's phone number and customer ID cannot both be empty at the same time.",
    "详情": "Details",
    "人": "Person",
    "3D相册制作中": "3D album creation in progress",
    "3D相册体验中": "3D album experiencing",
    "3D相册体验结束": "3D album experience ended",
    "您购买的3D相册正在精修中！": "You purchased 3D album is being refined!",
    "请求失败，点击重新加载": "Request failed, please click to reload.",
    "没有更多了": "There is no more",   
    "查看3D相册": "View 3D album",
    "倒计时": "countdown ",
    "天": "days",
    "建模完成":"modeling completed",
    "待修模":"To be repair",
    "修模中":"Repairing",
    "待打印":"To be print",
    "待建模":"To be model",
    "打印中":"printing",
    "未提交":"Not submitted",
    "快递中":"express delivery",
    "称净重":"Weighted(net)",
    "建模中":"Modelling",
    "3D相册已创建":"3D album created",
    "后道工序处理":"printed processing",
    "后道工序处理中":"printed processing",
    "订单信息": "Order information",
    "订单详情": "Order detail",
    "订单ID":"order ID",
    "拍摄时间":"shoot time",
    "复制":"copy",
    "重打":"Retype",
    "加打":"Placing order",
    "订单":"Order",
    "首页":"Home",
    "我的订单":"Order",
    "订单记录": "Order record",
    "联系门店": "Contact store",
    "拍摄门店": "Shooting location",
    "费用合计": "Total cost",
    "复制成功": "Copy succeeded",
    "复制失败": "Copy Failed",
    "保存成功": "Successfully saved",
    "暂无快递信息": "No tracking info",
    "退出登录...": "Logging out...",

    "渲染中...": "Rendering...",
    "数据加载中...": "Data loading...",
    "故障模型，请联系门店处理": "Faulty model, please contact the store for assistance",
    "3D相册体验已结束": "3D album experience has expired",
    "您购买的3D相册正在精修中，请稍候！": "The 3D album is being edited, please wait!",
    "您未购买3D相册": "You have not purchased a 3D album.",
    "体验还剩": "Experience remaining",
    "模型问题反馈": "Model issue feedback",
    "我要认领": "Claim",
    "纯色背景": "Solid Color Background",
    "已选择": "Selected",
    "主题背景": "Theme Background",

    "复位": "reset",
    "保存": "save",
    "旋转": "rotate",
    "停止": "stop",
    "换背景": "background",
    "收起": "Collapse",
    "展开": "Expand",
    "体验AR": "AR",
    "我的":"Profile",
    "关于我们":"About us",
    "语言":"Language",
    "头像":"Avatar",
    "账号":"Account",
    "性别":"Sex",
    "退出登录":"Logout",
    "确认退出登录？":"Confirm to log out?",
    "确认退出":"Confirm",
    "我再想想":"Cancel",
    "个人中心":"Personal Center",
    "请登录":"Please log in",
    "删除订单":"delete the order",
    "取消":"cancel",
    "退出AR":"Exit",
}
export default en