<template>
    <van-popup v-model:show="actionShow" round position="bottom">
        <van-picker
            :columns="actions"
            @cancel="actionShow = false"
            @confirm="onSelect"
        />
    </van-popup>
    <van-nav-bar v-if="!isWechat()"  :title="store.navTitle" safe-area-inset-top/>
    <div class="personalContainer">
        <div class="user-section">
            <div class="user-info-box" @click="toPerson">
                <div class="portrait-box">
                    <img
                    class="portrait"
                    :src="headimgUrl"
                    />
                </div>
                <div class="info-box" v-if="userInfo">
                    <div class="username">{{ nickname }}</div>
                    <van-icon class="arrow" name="arrow" size="18px" color="#767676" />
                </div>
                <div class="info-box" v-else>
                    <div @click="login" class="username">{{'请登录'.$t}}</div>
                </div>
            </div>
        </div>
        
        <!-- <div v-if="shootPerms" class="my_item" @click="takephoto" data-method="2">
            <div class="item_wrap">
                拍照
                <div class="right_arrow" ></div>
            </div>
        </div>
        <div class="my_item" @click="login" data-method="2">
            <div class="item_wrap">
                领取订单
                <div class="right_arrow" ></div>
            </div>
        </div> -->
        <!-- <div class="my_item" @click="toAddress" data-method="1">
            <div class="item_wrap">
                我的地址
                <div class="right_arrow" ></div>
            </div>
        </div> -->
        <!-- <div class="my_item">
            <div class="item_wrap">
                <button class="feedback" open-type="feedback">反馈意见</button>
                <div class="right_arrow" ></div>
            </div>
        </div>
        <div class="my_item">
            <div class="item_wrap">
                <button class="feedback" open-type="contact">在线客服</button>
                <div class="right_arrow" ></div>
            </div>
        </div> -->
        <!-- <div class="my_item" @click="toAbout">
            <van-field
                is-link
                readonly
                :label="'关于我们'.$t"
            />
        </div> -->
        <div class="my_item" @click="languageChange">
            <van-field
                v-model="fieldValue"
                is-link
                readonly
                :label="'语言'.$t"
            />
        </div>
        <div class="version">
            <div class="version_wrap">
                <div>v1.0.0</div>
            </div>
        </div>
    </div>
    <Tabbar></Tabbar>
   
</template>

<script setup lang="ts">
import { useTranslation } from "i18next-vue";
// import { showFailToast, showToast, FormInstance } from 'vant'
import { localStorage } from '@/utils/local-storage'
import {useStore} from '@/stores'
import { useTokenStore } from '@/stores/token'
import {headimgurl, nickname as fNickname} from './utils';

import {set as setLang} from '@/lang/i18n';

const { i18next } = useTranslation();

// const { t, locale } = useI18n();
const userInfo = ref({})
const store = useStore();
const tokenStore = useTokenStore();
const {isWechat} = tokenStore;
const showAvaModal = ref(false)
const version = ref('')
const fieldValue = ref('简体中文');
const actionShow = ref(false);

const actions = ref([
  {
    id: 1,
    value: "zh-CN",
    text: '简体中文',
  },
  {
    id: 2,
    value: "en",
    text: 'English',
  },
  {
    id: 3,
    value: "zh-TW",
    text: '繁體中文',
  },
  {
    id: 4,
    value: "ko",
    text: '韩语',
  },
]);

const nickname = computed(() => {
    return fNickname();
});

const initLang = actions.value.find((e: any) => e.value == i18next.language) || actions.value[0];
fieldValue.value = initLang?.text;

const shootPerms = ref(false)
const router = useRouter()

const headimgUrl = computed(() => {
    return headimgurl();
});

onMounted(()=> {
    userInfo.value = tokenStore.userInfo;
    shootPerms.value = tokenStore.userInfo.shootPerms;
    // const accountInfo = wx.getAccountInfoSync();
    // this.setData({ version: accountInfo.miniProgram.version || accountInfo.miniProgram.envVersion })
    
    // this.setData({
    //     userInfo: userInfo,
    //     shootPerms: userInfo?.shootPerms == true,
    // })
}) 

//  更多点击
const languageChange = () => {
    actionShow.value = !actionShow.value
}

// 选择语言
const onSelect = ({selectedOptions}) => {
    const lang = selectedOptions[0].value;
    // locale.value = selectedOptions[0].value;
    localStorage.set("i18nextLng", lang);
    actionShow.value = false;
    fieldValue.value = selectedOptions[0].text;
    // 更新标题语言
    setLang(lang).then((data) => {
        console.log('switch lang succeed', data)
        store.updateTitle("个人中心".$t)
    }).catch((e) => {
        console.log('switch lang faield', e);
    });

}


const login = (e) => {
    // 登录
    // WxLogin(this)
    // if (!IsLogin()) {
    //     this.setData({
    //         shootPerms: false
    //     });

    //     wx.navigateTo({
    //         url: '/pages/login/login',
    //     })
    //     return
    // } else {
    //     let method = e.currentTarget.dataset.method;
    //     if (method == 1) {
    //         wx.navigateTo({
    //             url: "/packageAddress/pages/address/address?select=true"
    //         })
    //     } else if (method == 2) {
    //         wx.navigateTo({
    //             url: "/pages/receive/receive"
    //         })
    //     }
    // }
}
// 测试
const takephoto = () => {
    // router.push({
    //     path: "/packageTakePhoto/pages/takePhoto/takePhoto"
    // })
}
// 跳转到个人资料页
const toPerson = () => {
    // if (IsLogin()) {
        router.push({
            path: '/person'
        })
    // } else {
        // showToast("请先登录");
        // setTimeout(() => {
            // router.push({
            //     path: '/pages/login/login',
            // })
    //     }, 1500)
    //     return
    // }
}

const toAddress = () => {
    router.push({
        path: '/address'
    })
}

const toAbout = () => {
    router.push({
        path: "/about"
    })
}


</script>

<style lang="less" scoped>
.container {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* padding: 200px 0; */
    box-sizing: border-box;
    background-color: rgba(245, 245, 245, 1.000000);
    padding: 50px 0;
}

.feedback {
    margin: 0;
    padding: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-size: 16px;
    font-weight: 400;
    flex-grow: 1;
  }


.userinfo {
    display: flex;
    /* flex-direction: column;
    align-items: center; */
}


.personalContainer {
    width: 100%;
    height: 100%;
    background-color: rgba(245, 245, 245, 1.000000);
}

.personalContainer .user-section {
    height: 150px;
    position: relative;
    padding: 50px 15px 0;
}

.user-info-box {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    .portrait {
        width: 65px;
        height: 65px;
        border: 2.5px solid #fff;
        border-radius: 50%;
    }
    .username {
        font-size: 20px;
        color: #333333;
        margin-left: 10px;
        font-weight: 700;
    }
    .info-box {
        display: flex;
        justify-content: center;
        align-items: center;
        .arrow {
            margin-left: 10px;
        }
    }
}


.right_arrow {
    border: solid gray;
    border-width: 0 3px 3px 0;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.my_item {
    width: 100%;
    height: 50px;
    display: flex;
    background: #ffffff;
    align-items: center;
    border-bottom: 1px solid gainsboro;
    .item_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin: 0 auto;
    }
}
.my_item button{line-height: 1.1;}
.my_item button::after{display: none;}

.button {
    width: 100%;
    background: white;
    border: none;
    text-align: left;
    padding: 0px;
    margin: 0 !important;
    line-height: 1.3;
    font-size: 16px;
    font-weight: 400;
}

.button::after {
    border: none;
    border-radius: 0
}

.version {
    display: flex;
    width: 100%;
    height: 50px;
    color: #606266;
    background: #ffffff;
    .version_wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        margin: 0 auto;
    }
}
</style>